import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import MelidataTrackerService from '@mshops-web-core/melidata-tracker';

import dataSales from '../../../../wordings/sales';
import Carousel from '../../../../components/Carousel';
import CardWithCta from '../../../../components/CardWithCta';
import { CardsBanner } from '../../../../constants/melidata-events/activate-events';

const ShopWithoutLimits = ({ i18n, deviceType, siteId }) => {
  const data = dataSales(i18n, siteId);

  const handleClickCta = useCallback(() => {
    MelidataTrackerService.notify(CardsBanner);
  }, []);

  return (
    <section className="ui-ms-landing-sales">
      <Carousel fade colorMode="light">
        {data.map(({ id, image, title, text, cta }, index) => (
          <CardWithCta
            key={id}
            image={image}
            title={title}
            text={text}
            cta={cta}
            type="submit"
            deviceType={deviceType}
            onClick={() => handleClickCta(index + 1)}
          />
        ))}
      </Carousel>
    </section>
  );
};

ShopWithoutLimits.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
};

export default ShopWithoutLimits;
