import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';

import MockSocialMedia from '../Mock/mockSocialMedia';

const SmAnimation = () => {
  const data = MockSocialMedia();
  const namespace = 'social-media';

  return (
    <div className={namespace}>
      <div className={`${namespace}__circle`}>
        <div
          className={`${namespace}__logos-cont ${namespace}__logos-animation`}
        >
          {data.logos.map(({ image, alt, id }) => (
            <div key={id} className={`${namespace}__logos`}>
              <Image src={image} alt={alt} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

SmAnimation.propTypes = {
  data: PropTypes.shape({
    logos: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default SmAnimation;
