import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '../../../../components/Tabs';
import Stepper from '../../../../components/Stepper';

const CreateYourShopDesktop = ({
  title,
  sellingTab,
  notSellingTab,
  sellingStep,
  notSellingStep,
  deviceType,
}) => {
  const [stepActiveItem, setStepActiveItem] = useState(0);
  const [stepItems, setStepItems] = useState(sellingStep);
  const stepRefs = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const targetIdAsNumber = parseInt(entry.target.id, 10);

          if (!Number.isNaN(targetIdAsNumber)) {
            setStepActiveItem(targetIdAsNumber);
          }
        }
      });
    }, options);

    stepRefs.current.forEach((ref) => {
      if (ref && ref instanceof Element) {
        observer.observe(ref);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [stepItems, sellingStep, notSellingStep]);

  const handleTabClick = useCallback(
    (index) => {
      if (index === 0) {
        setStepItems(sellingStep);
      } else if (index === 1) {
        setStepItems(notSellingStep);
      }

      setStepActiveItem(0);

      setTimeout(() => {
        const firstStepElement = stepRefs.current[0];

        if (
          firstStepElement &&
          typeof firstStepElement.scrollIntoView === 'function'
        ) {
          firstStepElement.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          });
        }
      }, 0);
    },
    [sellingStep, notSellingStep],
  );

  const handleSellingTabClick = useCallback(() => {
    handleTabClick(0);
  }, [handleTabClick]);

  const handleNotSellingTabClick = useCallback(() => {
    handleTabClick(1);
  }, [handleTabClick]);

  const containerHeight = stepItems
    ? `${stepItems.length * 100 + 100}vh`
    : '100vh';

  const namespace = 'ui-ms-landing-create-your-shop';

  return (
    <section className={`${namespace}`} style={{ height: containerHeight }}>
      <h2 className={`${namespace}__title`}>{title}</h2>
      <div className={`${namespace}__container--sticky`}>
        <Tabs>
          <TabList>
            <Tab index={0} onClick={handleSellingTabClick}>
              {sellingTab}
            </Tab>
            <Tab index={1} onClick={handleNotSellingTabClick}>
              {notSellingTab}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel index={0}>
              <Stepper
                steps={sellingStep}
                activeItem={stepActiveItem}
                deviceType={deviceType}
              />
            </TabPanel>
            <TabPanel index={1}>
              <Stepper
                steps={notSellingStep}
                activeItem={stepActiveItem}
                deviceType={deviceType}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
      <div>
        {stepItems.map(({ id }, index) => (
          <div
            key={id}
            id={id}
            className={`${namespace}__sticky-step`}
            ref={(element) => {
              stepRefs.current[index] = element;
            }}
          />
        ))}
      </div>
    </section>
  );
};

CreateYourShopDesktop.propTypes = {
  title: PropTypes.string.isRequired,
  sellingTab: PropTypes.string.isRequired,
  notSellingTab: PropTypes.string.isRequired,
  sellingStep: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      stepNumber: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      titleWithButton: PropTypes.shape({
        introText: PropTypes.string,
        buttonText: PropTypes.string,
        outroText: PropTypes.string,
      }),
    }),
  ).isRequired,
  notSellingStep: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      stepNumber: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      titleWithButton: PropTypes.shape({
        introText: PropTypes.string,
        buttonText: PropTypes.string,
        outroText: PropTypes.string,
      }),
    }),
  ).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
};

CreateYourShopDesktop.defaultProps = {
  sellingStep: {
    titleWithButton: null,
  },
};

export default CreateYourShopDesktop;
