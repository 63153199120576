const metaDescriptions = {
  MLA: 'Creá tu tienda online con Mercado Shops. Contá con todas las soluciones de Mercado Libre en tu tienda virtual. Mercado Pago incluido en tu tarifa. La única plataforma de ecommerce con Mercado Envíos Full.',
  MLB: 'Crie sua loja online com o Mercado Shops. Possui todas as soluções do Mercado Libre em sua loja virtual. Mercado Pago incluído na sua tarifa. A única plataforma de comércio eletrônico com Full Market Shipping.',
  default:
    'Crea tu tienda online con Mercado Shops. Cuenta con todas las soluciones de Mercado Libre en tu tienda virtual. Mercado Pago incluido en tu tarifa. La única plataforma de ecommerce con Mercado Envíos Full.',
};

const metaKeywords = {
  MLB: 'Comércio eletrônico, loja online, Mercado Shops, Mercado Libre, Mercado Pago, Mercado Shipping, vender online, vender no Instagram, vender no Facebook, vender no Mercado Libre, Loja Mercado Libre:',
  default:
    'Ecommerce, tienda online, Mercado Shops, Mercado Libre, Mercado Pago, Mercado Envíos, vender por Internet, Vender por Instagram, vender por Facebook, vender por Mercado Libre, Tienda Mercado Libre:',
};

export const getMetaDescription = (siteId) => {
  if (metaDescriptions[siteId]) {
    return metaDescriptions[siteId];
  }

  return metaDescriptions.default;
};

export const getMetaKeywords = (siteId) => {
  if (metaKeywords[siteId]) {
    return metaKeywords[siteId];
  }

  return metaKeywords.default;
};

export default getMetaDescription;
