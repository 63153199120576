import React from 'react';
import PropTypes from 'prop-types';

import MelidataTrackerService from '@mshops-web-core/melidata-tracker';

import IconNumber from '../IconNumber';
import Button from '../Button';
import {
  MeliSeller,
  NotMeliSeller,
} from '../../constants/melidata-events/activate-events';

const Step = ({ stepNumber, active, title, titleWithButton = null }) => {
  const namespace = 'ui-ms-landing-step';

  const notificationMap = {
    meli_seller: MeliSeller,
    not_meli_seller: NotMeliSeller,
  };

  const handleClickButtonSubmit = () => {
    const notification = notificationMap[titleWithButton?.buttonLabelEvent];

    if (notification) {
      MelidataTrackerService.notify(notification);
    }
  };

  return (
    <div
      className={`${namespace}__item ${
        active ? `${namespace}__item--active` : ``
      }`}
    >
      <IconNumber
        number={stepNumber}
        variant="filled"
        colorPalette="yellow"
        size="md"
      />
      <div className={`${namespace}__tracker`} />
      <div className={`${namespace}__content`}>
        <h3 className={`${namespace}__title`}>
          {titleWithButton ? (
            <>
              {titleWithButton.introText}
              <Button
                className="ui-ms-landing-step__button"
                type="submit"
                hasIconRight={false}
                text={titleWithButton.buttonText}
                onClick={handleClickButtonSubmit}
              />
              {titleWithButton.outroText}
            </>
          ) : (
            title
          )}
        </h3>
      </div>
    </div>
  );
};

Step.propTypes = {
  stepNumber: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  titleWithButton: PropTypes.shape({
    introText: PropTypes.string,
    buttonText: PropTypes.string,
    buttonLabelEvent: PropTypes.string,
    outroText: PropTypes.string,
  }),
};

export default Step;
