const contentFaq = (i18n) => ({
  title: i18n.gettext('Preguntas frecuentes'),
  items: [
    {
      id: 1,
      number: '1',
      /* i18n-next-line except: ["es-AR"] */
      title: i18n.gettext('¿Por qué vender mis productos en Mercado Shops?'),
      /* i18n-next-line except: ["es-AR"] */
      description: i18n.gettext(
        'Porque te permite crear tu página web gratis, con dominio propio y ampliar tu negocio, sumando un nuevo canal de ventas.',
      ),
    },
    {
      id: 2,
      number: '2',
      /* i18n-next-line except: ["es-AR"] */
      title: i18n.gettext(
        '¿Tengo que pagar costos extras para acceder a todas las funcionalidades de mi Shop?',
      ),
      description: i18n.gettext(
        'No. A diferencia de otras propuestas, accedes a todas las funcionalidades de tu tienda virtual sin costos extras ni planes.',
      ),
    },
    {
      id: 3,
      number: '3',
      /* i18n-next-line except: ["es-AR"] */
      title: i18n.gettext(
        'Mis ventas en mi Mercado Shops ¿mejoran mi reputación en Mercado Libre?',
      ),
      /* i18n-next-line except: ["es-AR"] */
      description: i18n.gettext(
        'Sí. Cada venta que hagas en tu tienda en línea, se sumará directamente a tu volumen de ventas generales y a tu reputación como vendedor en Mercado Libre.',
      ),
    },
    {
      id: 4,
      number: '4',
      /* i18n-next-line except: ["es-AR"] */
      title: i18n.gettext(
        '¿Cómo gestionar mis publicaciones si vendo en Mercado Libre y Mercado Shops?',
      ),
      /* i18n-next-line except: ["es-AR"] */
      description: i18n.gettext(
        'Desde "Publicaciones" podrás ver tus productos, el canal de venta donde están apareciendo y si están activos o inactivos. ',
      ),
    },
    {
      id: 5,
      number: '5',
      /* i18n-next-line except: ["es-AR", [pt-BR]] */
      title: i18n.gettext(
        '¿Puedo personalizar mi Mercado Shops para darle la identidad de mi negocio? ',
      ),
      /* i18n-next-line except: ["es-AR"] */
      description: i18n.gettext(
        '¡Sí! No hace falta saber de programación para crear tu sitio web. Tu tienda virtual cuenta con un editor de diseño incorporado y plantillas gratuitas.',
      ),
    },
  ],
});

export default contentFaq;
