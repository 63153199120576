import React from 'react';
import PropTypes from 'prop-types';

import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '../../../../components/Tabs';
import Stepper from '../../../../components/Stepper';

const CreateYourShopMobile = ({
  title,
  sellingTab,
  notSellingTab,
  sellingStep,
  notSellingStep,
  deviceType,
}) => {
  const namespace = 'ui-ms-landing-create-your-shop';

  return (
    <section className={`${namespace}`}>
      <h2 className={`${namespace}__title`}>{title}</h2>
      <Tabs>
        <div className={`${namespace}__tabs--gap`}>
          <TabList>
            <Tab index={0}>{sellingTab}</Tab>
            <Tab index={1}>{notSellingTab}</Tab>
          </TabList>
        </div>
        <TabPanels>
          <TabPanel index={0}>
            <Stepper
              steps={sellingStep}
              activeItem={0}
              deviceType={deviceType}
            />
          </TabPanel>
          <TabPanel index={1}>
            <Stepper
              steps={notSellingStep}
              activeItem={0}
              deviceType={deviceType}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </section>
  );
};

CreateYourShopMobile.propTypes = {
  title: PropTypes.string.isRequired,
  sellingTab: PropTypes.string.isRequired,
  notSellingTab: PropTypes.string.isRequired,
  sellingStep: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      stepNumber: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      titleWithButton: PropTypes.shape({
        introText: PropTypes.string,
        buttonText: PropTypes.string,
        outroText: PropTypes.string,
      }),
    }),
  ).isRequired,
  notSellingStep: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      stepNumber: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      titleWithButton: PropTypes.shape({
        introText: PropTypes.string,
        buttonText: PropTypes.string,
        outroText: PropTypes.string,
      }),
    }),
  ).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
};

CreateYourShopMobile.defaultProps = {
  sellingStep: {
    titleWithButton: null,
  },
};

export default CreateYourShopMobile;
