import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';
import MelidataTrackerService from '@mshops-web-core/melidata-tracker';

import Button from '../../../../components/Button';
import contentEstilo from '../../../../wordings/estilo';
import { DesignBanner } from '../../../../constants/melidata-events/activate-events';

const Estilo = ({ i18n, deviceType, siteId }) => {
  const ButtonSize = {
    desktop: 'lg',
    tablet: 'sm',
    mobile: 'sm',
  };

  const nameclass = 'ui-ms-landing-estilo';
  const content = contentEstilo(i18n, siteId);
  const { title, text, button, imageMobile, image } = content;

  const handleClickButton = () => {
    MelidataTrackerService.notify(DesignBanner);
  };

  return (
    <section className={nameclass}>
      <div className={`${nameclass}__container`}>
        <div className={`${nameclass}__content`}>
          <div className={`${nameclass}__title-col`}>
            <h2 className={`${nameclass}__title`}>{title}</h2>
          </div>
          <div className={`${nameclass}__text-col`}>
            <p className={`${nameclass}__text`}>{text}</p>
            <Button
              text={button}
              colorPalette="black"
              variant="tertiary"
              size={ButtonSize[deviceType]}
              className={`${nameclass}__button`}
              onClick={handleClickButton}
            />
          </div>
        </div>
        <div className={`${nameclass}__cont-image`}>
          {deviceType === 'desktop' || deviceType === 'tablet' ? (
            <Image src={image} className={`${nameclass}__image`} alt="" />
          ) : (
            <Image src={imageMobile} className={`${nameclass}__image`} alt="" />
          )}
        </div>
      </div>
    </section>
  );
};

Estilo.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.string,
  siteId: PropTypes.string.isRequired,
};

export default Estilo;
