import MLA from './mla';
import MLM from './mlm';
import MCO from './mco';
import MLC from './mlc';
import MLB from './mlb';

const getChartItems = (i18n, siteId) => {
  const CHART_ITEMS = {
    MLA: MLA(i18n),
    MLM: MLM(i18n),
    MCO: MCO(i18n),
    MLC: MLC(i18n),
    MLB: MLB(i18n),
  };

  return CHART_ITEMS[siteId];
};

const ContentChart = (i18n, siteId) => ({
  /* i18n-next-line except: ["es-AR"] */
  title: i18n.gettext('Con Mercado Shops tener tu tienda online es gratis'),
  /* i18n-next-line except: ["es-AR"] */
  copy: i18n.gettext(
    'Con todas las funcionalidades, sin planes ni pagos extras.',
  ),
  /* i18n-next-line except: ["es-AR"] */
  shopButton: i18n.gettext('Crear tienda online'),
  /* i18n-next-line except: ["pt-BR", "es-AR"] */
  functionalityButton: i18n.gettext('+ funcionalidades'),
  chartItems: getChartItems(i18n, siteId),
});

export default ContentChart;
