import commonItemPercent from './commonItemPercent';
import commonItems from './commonItems';

const salePercent = '3,99%';

const MLA = (i18n) => [
  {
    id: 1,
    /* i18n-next-line except: ["pt-BR", "es-AR"] */
    label: i18n.gettext('Vendé en un pago o en cuotas con interés'),
    value: commonItemPercent(i18n, salePercent),
    icon: '',
  },
  ...commonItems(i18n),
];

export default MLA;
