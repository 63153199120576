import React from 'react';
import PropTypes from 'prop-types';

import StepperDesktop from './StepperDesktop';
import StepperMobile from './StepperMobile';

const Stepper = ({ steps, activeItem, deviceType }) => {
  const StepperByDevice = {
    mobile: StepperMobile,
    tablet: StepperMobile,
    desktop: StepperDesktop,
  };

  const StepperComponent = StepperByDevice[deviceType] || StepperDesktop;

  return (
    <StepperComponent
      steps={steps}
      activeItem={activeItem}
      deviceType={deviceType}
    />
  );
};

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepNumber: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      titleWithCTA: PropTypes.shape({
        introText: PropTypes.string,
        buttonText: PropTypes.string,
        buttonLabelEvent: PropTypes.string,
        outroText: PropTypes.string,
      }),
    }),
  ).isRequired,
  activeItem: PropTypes.number.isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
};

Stepper.defaultProps = {
  steps: {
    titleWithButton: null,
  },
};

export default Stepper;
