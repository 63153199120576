import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';

import Step from './Step';
import Carousel from '../Carousel';

const StepperMobile = ({ steps, deviceType }) => {
  const [activeItem, setActiveItem] = useState(0);

  const [stepImageNav, setStepImageNav] = useState(null);
  const [stepItemNav, setStepItemNav] = useState(null);

  const stepImageRef = useRef(null);
  const stepItemRef = useRef(null);

  useEffect(() => {
    setStepImageNav(stepImageRef.current);
    setStepItemNav(stepItemRef.current);
  }, []);

  const namespace = 'ui-ms-landing-stepper';

  const stepsImageCarousel = {
    asNavFor: stepItemNav,
    afterChange: (current) => setActiveItem(current),
    arrows: false,
    dots: true,
    safeGapItems: false,
    colorMode: 'light',
    infinite: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          fade: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  const stepsItemsCarousel = {
    asNavFor: stepImageNav,
    colorMode: 'light',
    infinite: false,
    arrows: false,
    safeGapItems: false,
    dots: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          fade: false,
          slidesToShow: 1.1,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className={`${namespace}`}>
      <div className={`${namespace}__images`}>
        <Carousel {...stepsImageCarousel} ref={stepImageRef}>
          {steps.map(({ id, image, title }) => (
            <Image key={id} src={image} alt={title} />
          ))}
        </Carousel>
      </div>
      <div className={`${namespace}__content-items`}>
        <Carousel {...stepsItemsCarousel} ref={stepItemRef}>
          {steps.map(({ id, stepNumber, title, titleWithButton }) => (
            <Step
              key={id}
              stepNumber={stepNumber}
              active={id === activeItem}
              title={title}
              titleWithButton={titleWithButton}
              deviceType={deviceType}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

StepperMobile.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepNumber: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      titleWithButton: PropTypes.shape({
        introText: PropTypes.string,
        buttonText: PropTypes.string,
        buttonLabelEvent: PropTypes.string,
        outroText: PropTypes.string,
      }),
    }),
  ).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
};

StepperMobile.defaultProps = {
  steps: {
    titleWithButton: null,
  },
};

export default StepperMobile;
