import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import { TabsContext } from './TabsContext';

const Tab = ({ children, index, onClick }) => {
  const { activeTab, handleTabClick, tabRefs } = useContext(TabsContext);

  const namespace = 'ui-ms-landing-tabs';

  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }

    handleTabClick(index);
  };

  const tabClass = classnames(`${namespace}__tab`, {
    [`${namespace}__tab--active`]: index === activeTab,
  });

  return (
    <button
      type="button"
      ref={(el) => {
        tabRefs.current[index] = el;
      }}
      className={tabClass}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

export default Tab;
