import commonItemFinancing from './commonItemFinancing';
import commonItemPercent from './commonItemPercent';
import commonItems from './commonItems';

const salePercent = '3,7%';

const MCO = (i18n) => [
  {
    id: 1,
    label: commonItemFinancing(i18n),
    value: commonItemPercent(i18n, salePercent),
    icon: '',
  },
  ...commonItems(i18n),
];

export default MCO;
