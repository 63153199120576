import commonItemFinancing from './commonItemFinancing';
import commonItems from './commonItems';

const MLB = (i18n) => [
  {
    id: 0,
    /* i18n-next-line except: ["es-AR"] */
    label: i18n.gettext('Vende al contado o en cuotas con interés'),
    value: i18n.gettext('¡Gratis!'),
    icon: '',
  },
  {
    id: 1,
    label: commonItemFinancing(i18n),
    value: '5,99%',
    icon: '',
  },
  ...commonItems(i18n),
];

export default MLB;
